import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, Typography, Card, CardContent, CardMedia } from '@mui/material';
import { IconButton, Drawer, List, ListItem, ListItemText, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './Header.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from '../images/Log.png';

function Header() {
  const [currentMenu, setCurrentMenu] = useState(null);
  const isLargeScreen = useMediaQuery('(min-width:600px)');
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('no-overflow', !!currentMenu);
  }, [currentMenu]);

  const navItems = [
    {
      label: "Web Development",
      submenu: [
        { name: "Custom Website", link: "/customwebsite", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224997/web_dev_y4fiey.webp" },
        { name: "E-commerce", link: "/ecommerce", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224997/e-commerce_b0t4ji.webp" },
        { name: "Maintenance", link: "/maintenance", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224996/web_main_zcz4aw.webp" },
        { name: "Website Updates", link: "/websiteupdates", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224996/website_update_esyrhz.webp" },
      ],
    },
    {
      label: "App Development",
      submenu: [
        { name: "iOS Apps", link: "/iosapps", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224999/ios_wuyvxg.webp" },
        { name: "Android Apps", link: "/androidapps", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224998/android_qgcgiv.webp" },
        { name: "Cross-Platform", link: "/crossplatform", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224998/crossplatform_qxcfrm.webp" },
        { name: "Desktop Apps", link: "/desktopapps", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224998/desktop_app_rjnn2a.webp" },
      ],
    },
    {
      label: "Design",
      submenu: [
        { name: "UI/UX Design", link: "/uiux", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224997/ui_ihnxwt.webp" },
        { name: "Branding", link: "/branding", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224998/branding_lsoeat.webp" },
        { name: "Graphic Design", link: "/graphicdesign", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224998/graphic_design_bv20ud.webp" },
        { name: "E-Learnings", link: "/elearnings", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224998/e-learning_pimfqp.webp" },
      ],
    },
    {
      label: "Data Analytics",
      submenu: [
        { name: "Data Analysis", link: "/dataanalysis", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224998/data_analytics_tmnvf0.webp" },
        { name: "Visualization", link: "/visualization", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224998/data_visualization_x0s22q.webp" },
        { name: "Machine Learning", link: "/machinelearning", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224997/machine_learning_dynd1v.webp" },
        { name: "Data Intelligence", link: "/businessintelligence", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735224996/business_int_frtt7y.webp" },
      ],
    },
    {
      label: "Company",
      submenu: [
        { name: "About Us", link: "/about", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735234603/DALL_E_2024-12-26_22.28.55_-_A_clean_and_modern_animated-style_illustration_for_an_About_Us_page._The_design_features_a_group_of_diverse_team_members_standing_together_represen_deuekg.webp" },
        { name: "Our Team", link: "/team", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735234577/ourteam_vmv36f.webp" },
        { name: "Careers", link: "/careers", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735234577/careers_p7khiu.webp" },
        { name: "Contact", link: "/contact", image: "https://res.cloudinary.com/dt98ksead/image/upload/v1735234577/contactus_ghuiyd.webp" }
        
      ],
    },
  ];

  const handleMenuOpen = (menu) => setCurrentMenu(menu);
  const handleMenuClose = () => setCurrentMenu(null);
  const toggleDrawer = (open) => setDrawerOpen(open);

  const renderMegaMenu = () => {
    const menu = navItems.find(item => item.label.toLowerCase().replace(' ', '') === currentMenu);
    if (!menu) return null;

    return (
      <Box className="megaMenu" onMouseEnter={() => handleMenuOpen(currentMenu)} onMouseLeave={handleMenuClose}>
        {menu.submenu.map((submenuItem, index) => (
          <Link to={submenuItem.link} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Card key={index} className="megaMenuCard" style={{backgroundColor:'transparent'}}>
            <CardMedia component="img" alt={submenuItem.name} height="200" image={submenuItem.image} />
            <CardContent>
                <Typography variant="h6" sx={{color:"white"}}>{submenuItem.name}</Typography>
            </CardContent>
          </Card>
          </Link>
        ))}
      </Box>
    );
  };

  return (
    <AppBar position="static" className="header" color="transparent" elevation={0}>
      <Toolbar className="toolbar">
        <Box className="logoContainer">
          <Link to="/" style={{ textDecoration: 'none' }}>
            <img src={Logo} alt="Arc Codes Logo" className="logo" />
          </Link>
        </Box>

        {isLargeScreen ? (
          <Box className="navLinks">
            {navItems.map((item, index) => (
              <Button
                key={index}
                className="navButton"
                style={{color: "black"}}
                onMouseEnter={() => handleMenuOpen(item.label.toLowerCase().replace(' ', ''))}
                endIcon={<ArrowDropDownIcon />}
              >
                {item.label}
              </Button>
            ))}
            <Button
              href="https://meetings.hubspot.com/silal-anwar"
              variant="contained"
              color="primary"
              className="demoButton"
            >
              Book a Call
            </Button>
          </Box>
        ) : (
          <IconButton className="menuIcon" onClick={() => toggleDrawer(true)}>
            <MenuIcon style={{ color: 'white' }} />
          </IconButton>
        )}

        <Drawer anchor="right" open={drawerOpen} onClose={() => toggleDrawer(false)}>
          <Box className="drawerContent" role="presentation" onClick={() => toggleDrawer(false)}>
            <List>
              {navItems.map((item, index) => (
                <Box key={index}>
                  <ListItem>
                    <Typography variant="h6">{item.label}</Typography>
                  </ListItem>
                  <Divider />
                  {item.submenu.map((submenuItem, subIndex) => (
                    <Link to={submenuItem.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem key={subIndex}>
                      <img
                        src={submenuItem.image}
                        alt={submenuItem.name}
                        style={{ width: "40px", height: "40px", marginRight: "10px" }}
                      />
                      <ListItemText>
                          {submenuItem.name}  
                      </ListItemText>
                    </ListItem>
                    </Link>
                  ))}
                </Box>
              ))}
            </List>
          </Box>
        </Drawer>

        {renderMegaMenu()}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
