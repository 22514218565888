import React from 'react';
import {Button} from '@mui/material';
import './OurTeam.css'; // Create a CSS file for styling

const teamMembers = [
  { name: 'Ahsan Mughal', role: 'CEO', image: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735295920/ahsan_c_iwrv68.jpg', backImage: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735235215/WhatsApp_Image_2024-12-26_at_22.26.11_fa7a10ac_fwjqyb.jpg' },
  { name: 'Silal Anwar', role: 'CTO', image: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735295809/silal_c_bm2xl7.jpg', backImage: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735294847/silal_s6i8fi.jpg' },
  { name: 'Sardar Ali', role: 'CFO', image: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735310588/364599b2-ed3d-4a57-8361-7560f9773e50.png', backImage: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735310607/f0437b9b-122f-4040-9413-e9ec7eb5d790.png' },
  { name: 'Sawera Nazir', role: 'Operations Executive', image: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735304143/sawera_c_citzja.jpg', backImage: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735304144/sawera_zkxq8m.jpg' },
  { name: 'Mohsin Amjad', role: 'Marketing Executive', image: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735303854/mohsin_h78hcj.jpg', backImage: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735303853/mohsin1_zbpagq.jpg' },
  { name: 'Awais Khan', role: 'Development Lead', image: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735296113/awais_c_yca5hl.jpg', backImage: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735296849/awais_izpb2z.jpg' },
  { name: 'Ali Raza', role: 'Creative Lead', image: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735296114/ali_c_fwh8wt.jpg', backImage: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735295098/ali_gjhyis.jpg' },
  { name: 'Anam Riaz', role: 'Sales Lead', image: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735296247/664f27cfacc2764048afce9f_uniza_402x-p-800_o5vo28.png', backImage: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735296247/664f27cfacc2764048afce9f_uniza_402x-p-800_o5vo28.png' },
  { name: 'Haram Noor', role: 'Marketing Lead', image: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735297225/haram_c_ofd7xu.jpg', backImage: 'https://res.cloudinary.com/dt98ksead/image/upload/v1735297225/haram_gyvn1g.jpg' },
];

const OurTeam = () => {
  return (
    <>
    <video autoPlay loop muted className="heroVideo">
          <source src="https://res.cloudinary.com/dt98ksead/video/upload/v1731572384/3129957-hd_1280_720_25fps_upoccs.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
    
    <div className="our-team-container">
        
      <h2 className="title">Our Team</h2>
      <p className="team-intro">At Arc Codes, we are proud to have a dynamic and talented team that drives our vision forward. Each member brings unique skills and creativity, ensuring the success of our projects and satisfaction of our clients.</p>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <div className="avatar-wrapper">
              <div className="avatar front" style={{ backgroundImage: `url(${member.image})` }}></div>
              <div className="avatar back" style={{ backgroundImage: `url(${member.backImage})` }}></div>
            </div>
            <h3 className="name">{member.name}</h3>
            <p className="role">{member.role}</p>
          </div>
        ))}
      </div>
      <div className="join-us-section">
        <h3>Interested in joining us?</h3>
        <p>We are always on the lookout for talented individuals to join our growing team. If you are passionate about what you do, we would love to hear from you!</p>
        <Button  className="join-us-button" sx={{marginTop:"20px"}}>JOIN US NOW</Button>
      </div>
    </div>
    </>
  );
};

export default OurTeam;

