import React from 'react';
import { Box, Button, Grid, Typography, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Helmet } from "react-helmet";
import { CheckCircle, People, TrendingUp } from '@mui/icons-material';
import './Home.css';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import DevicesIcon from '@mui/icons-material/Devices';
import PeopleIcon from '@mui/icons-material/People';

import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SearchIcon from '@mui/icons-material/Search';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import SliderHome from '../components/SliderHome';

function Home() {
  // Slider settings for client logos
  const settings = {
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box className="homeContainer">
      {/* Hero Section with Background Video */}
      <Box className="heroSection">
      <video autoPlay loop muted className="heroVideo">
          <source src="https://res.cloudinary.com/dt98ksead/video/upload/v1731572384/3129957-hd_1280_720_25fps_upoccs.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Box className="heroTextContainer">
          <Typography variant="h3" className="heroTitle">
            Your Cheat Code to Success
          </Typography>
          <Typography variant="h6" className="heroSubtitle" sx={{marginTop:"1rem"}}>
            Innovative solutions that bring your ideas to life.
          </Typography>
          <Button
            component={Link}
            to="/contact"
            variant="contained"
            style={{backgroundColor: "#e72127"}}
            className="exploreButton"
            sx={{marginTop:"1rem"}}
          >
            Contact Us
          </Button>
        </Box>
      </Box>

      {/* <SliderHome/> */}

      {/* Services Overview Section */}
      <Box className="servicesSection">
        <Typography variant="h3" className="sectionTitle" sx={{color: "white", marginBottom:"3rem"}}>
          Our Popular Services
        </Typography>
        <Grid container spacing={4} justifyContent="center" className="servicesGrid">
          <Grid item xs={12} md={6} lg={4}>
          <Link to="/customwebsite" onClick={() => {window.scrollTo(0, 0);}}>
          <Card
            className="serviceCard"
            sx={{
              position: "relative", // Enables positioning child elements absolutely
              overflow: "hidden",
              borderRadius: "25px",
              background: `url('https://res.cloudinary.com/dt98ksead/image/upload/v1736156226/74534957-29a7-4535-9983-6ab943154148.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              border: "2px solid #f0f0f0",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.65)", // Overlay to ensure text readability
                zIndex: 1,
              }}
            />
            <CardContent
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", // Centers the text on the card
                textAlign: "center",
                color: "#fff", // Ensure the text contrasts well with the background
                zIndex: 2,
              }}
            >
              <Typography variant="h6" className="serviceTitle" sx={{ fontWeight: "bold" }}>
                Website Development
              </Typography>
              <Typography variant="body2" className="serviceDescription">
                Craft visually stunning, responsive websites that perfectly capture your brand essence, engage your audience, and deliver an exceptional user experience.
              </Typography>
            </CardContent>
          </Card>
        </Link>

          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <Link to="/androidapps" onClick={() => {window.scrollTo(0, 0);}}>
          <Card
            className="serviceCard"
            sx={{
              position: "relative", // Enables positioning child elements absolutely
              overflow: "hidden",
              borderRadius: "25px",
              background: `url('https://res.cloudinary.com/dt98ksead/image/upload/v1735298726/533b1b0e-e785-4101-9c6a-f03871b5ecf2.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              border: "2px solid #f0f0f0",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.65)", // Overlay for text readability
                zIndex: 1,
              }}
            />
            <CardContent
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", // Centers the text
                textAlign: "center",
                color: "#fff",
                zIndex: 2,
              }}
            >
              <Typography variant="h6" className="serviceTitle" sx={{ fontWeight: "bold" }}>
                Mobile Application Development
              </Typography>
              <Typography variant="body2" className="serviceDescription">
                Innovative mobile app solutions for Android, iOS and Cross-platform.
              </Typography>
            </CardContent>
          </Card>
        </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <Link to="/seo" onClick={() => {window.scrollTo(0, 0);}}>
          <Card
            className="serviceCard"
            sx={{
              position: "relative",
              overflow: "hidden",
              borderRadius: "25px",
              background: `url('https://res.cloudinary.com/dt98ksead/image/upload/v1735300484/7aad8935-830c-431a-bc05-e3b2290578a0.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              border: "2px solid #f0f0f0",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.65)",
                zIndex: 1,
              }}
            />
            <CardContent
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "#fff",
                zIndex: 2,
              }}
            >
              <Typography variant="h6" className="serviceTitle" sx={{ fontWeight: "bold" }}>
                SEO & Marketing
              </Typography>
              <Typography variant="body2" className="serviceDescription">
                Increase visibility and attract more customers with our marketing services.
              </Typography>
            </CardContent>
          </Card>
        </Link>
          </Grid>
        </Grid>
      </Box>


      {/* Approach Section */}
      <Box className="approach-section" sx={{padding: "3rem 1.5rem" }}>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{ color: "white", marginBottom: "2rem" }}
        align="center"
      >
        Empower Your Online Presence
      </Typography>
      <Grid container spacing={1} alignItems="center" sx={{backdropFilter: "blur(5px)"}}>
        {/* Image Section */}
        <Grid item xs={12} md={5}>
          <Box className="approach-image" sx={{ textAlign: "center" }}>
            <img
              src="https://res.cloudinary.com/dt98ksead/image/upload/v1735922584/937f4106-5fd6-438d-9b8d-d4d4efd170d4.png"
              alt="UI/UX Design"
              style={{ width: "70%", borderRadius: "50px", border: "2px solid #fff" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="approach-txt" sx={{ color: "white"}}>
            <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: "1.5rem" }}>
              <DevicesIcon sx={{ fontSize: "4rem", marginRight: "1rem", color: "#4caf50" }} />
              <Box sx={{justifyContent: "center"}}>
                <Typography variant="h6" gutterBottom>Crafting Exceptional UI/UX Design</Typography>
                <Typography variant="body1">
                Your website is the face of your brand, and first impressions matter. At Arc Codes, we specialize in UI/UX design that blends aesthetics with functionality. Our designs are user-centric, ensuring that visitors not only enjoy a seamless browsing experience but also take the desired actions. From intuitive navigation to responsive layouts, we create websites that captivate and convert.
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: "1.5rem" }}>
              <SearchIcon sx={{ fontSize: "4rem", marginRight: "1rem", color: "#2196f3" }} />
              <Box sx={{justifyContent: "center"}}>
                <Typography variant="h6" gutterBottom>Building SEO-Optimized Websites for Better Visibility</Typography>
                <Typography variant="body1">
                Standing out in today’s competitive digital landscape requires more than just a good-looking website. That’s why our team focuses on SEO-friendly website development, ensuring your site ranks higher on search engines like Google. From keyword optimization to fast-loading pages and mobile responsiveness, we implement strategies that boost your online presence and attract organic traffic.
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <LightbulbIcon sx={{ fontSize: "4rem", marginRight: "1rem", color: "#ff5722" }} />
              <Box sx={{justifyContent: "center"}}>
                <Typography variant="h6" gutterBottom>Transforming Ideas into Digital Success</Typography>
                <Typography variant="body1">
                Every business is unique, and your website should reflect your distinct brand identity. At Arc Codes, we collaborate closely with our clients to bring their visions to life. Whether you’re launching a custom e-commerce platform, a dynamic corporate website, or a creative portfolio, we provide tailored solutions that align with your business goals and drive measurable success.              
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>

    
      {/* Why Choose Us Section */}
      <Box className="whyChooseSection" sx={{marginTop:"3rem"}}>
        <Typography variant="h3" className="sectionTitle" sx={{color: "white", marginBottom:"3rem"}}>
          Why Choose Arc Codes?
        </Typography>
        <Grid container spacing={4} justifyContent="center" className="whyChooseGrid">
          <Grid item xs={12} md={4}>
            <Box className="whyChooseCard" sx={{borderRadius: "25px", border: "3px solid #f0f0f0"}}>
              <People className="whyChooseIcon" />
              <Typography variant="h5" className="whyChooseTitle">
                Experienced Team
              </Typography>
              <Typography variant="body2" className="whyChooseDescription">
              At Arc Codes, we take pride in our team of highly experienced developers and designers. With years of expertise in web development, UI/UX design, and SEO strategies, our professionals are dedicated to crafting exceptional digital experiences. From startups to established businesses, our expertise ensures every project is executed with precision and creativity.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="whyChooseCard" sx={{borderRadius: "25px", border: "3px solid #f0f0f0"}}>
              <CheckCircle className="whyChooseIcon" />
              <Typography variant="h5" className="whyChooseTitle">
                Custom Solutions
              </Typography>
              <Typography variant="body2" className="whyChooseDescription">
              No two businesses are the same, and neither should their websites be. That’s why we specialize in delivering custom website solutions tailored to your specific requirements. Whether you need a sleek corporate site, an innovative e-commerce platform, or an engaging portfolio, we design and develop solutions that align perfectly with your brand and goals.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="whyChooseCard" sx={{borderRadius: "25px", border: "3px solid #f0f0f0"}}>
              <TrendingUp className="whyChooseIcon" />
              <Typography variant="h5" className="whyChooseTitle">
                Results-Driven
              </Typography>
              <Typography variant="body2" className="whyChooseDescription">
              We don’t just build websites; we build success stories. Our results-driven approach focuses on delivering measurable outcomes, from increased traffic and higher search engine rankings to enhanced user engagement and conversions. At Arc Codes, your growth and success are our priorities. Boost your online presence and achieve your business goals with our innovative solutions.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Testimonials Section */}
      <Box className="testimonialsSection" sx={{marginTop:"3rem"}}>
        <Typography variant="h3" className="sectionTitle" sx={{color: "white", marginBottom:"3rem"}}>
          What Our Clients Say
        </Typography>
        <Grid container spacing={4} justifyContent="center" className="testimonialsGrid">
          <Grid item xs={12} md={6} lg={4}>
            <Box className="testimonialCard">
              <Typography variant="body1" className="testimonialText">
                "Arc Codes delivered exactly what we needed, on time and with superb quality. Highly recommended!"
              </Typography>
              <Typography variant="subtitle2" className="testimonialAuthor">
                - Saad, Business Executive of Tracking World
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="testimonialCard">
              <Typography variant="body1" className="testimonialText">
                "The team at Arc Codes understood our vision and transformed it into a great product. Fantastic job!"
              </Typography>
              <Typography variant="subtitle2" className="testimonialAuthor">
                - Amel, Chief Editor of Earthwise
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="testimonialCard">
              <Typography variant="body1" className="testimonialText">
                "Arc Codes delivered on the project well within the agreed time. Great work!"
              </Typography>
              <Typography variant="subtitle2" className="testimonialAuthor">
                - Peter, CEO of Orin International
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Clients Logo Slider Section */}
      <Box className="clientsSection" sx={{marginTop:"3rem"}}>
        <Typography variant="h3" className="sectionTitle" sx={{color: "white", marginBottom:"3rem"}}>
          Companies That Trust Us
        </Typography>
        <Slider {...settings} className="clientsSlider">
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731573645/Fuji_Logo_l5wr7y.png" alt="Client 4" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731574605/Honda_20Ring_20Road_ew7gkw.webp" alt="Client 1" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731681020/Tracking-World-PNG-Logo_qcjuqf.png" alt="Client 1" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731573716/Logo_ohwxwg.png" alt="Client 2" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731573864/4-1_bdvtqo.png" alt="Client 3" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/b_rgb:000000/v1731573803/earthwise-logo-white-2-300x70_dbbgtj.png" alt="Client 1" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731573645/tape_logo_1_mmeb3a.jpg" alt="Client 5" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1731573645/opto_jpzzhp.jpg" alt="Client 6" />
          </Box>
          <Box className="clientLogo">
            <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1735923525/OrinInternational-1_lnamsk.png" alt="Client 7" />
          </Box>
        </Slider>
      </Box>
    </Box>
  );
}

export default Home;
