import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import './ErrorPage.css'; // Custom styles for error page

const ErrorPage = () => {
  return (
    <div className="error-container">
      <div className="error-content">
        <h1 className="error-title">Oops!</h1>
        <h2 className="error-message">404 - Page Not Found</h2>
        <p className="error-description">
          Sorry, the page you're looking for doesn't exist. But don't worry, we
          can help you find your way back.
        </p>
        <div className="error-actions">
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/"
            className="error-button"
          >
            Go Back Home
          </Button>
        </div>
      </div>
      <div className="error-image">
        <img
          src="https://img.icons8.com/ios/452/404-error.png"
          alt="404 Error"
          className="error-icon"
        />
      </div>
    </div>
  );
};

export default ErrorPage;
