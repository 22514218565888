import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, InputAdornment } from '@mui/material';
import './ContactUs.css';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import emailjs from '@emailjs/browser'; // Import EmailJS
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    position: '',
    company: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.from_name || !formData.from_email || !formData.message) {
      setErrorMessage('Please fill out all required fields.');
      setSuccessMessage('');
      return;
    }

    // Send email via EmailJS
    emailjs
      .send(
        'service_mw8vzfr', // Replace with your EmailJS service ID
        'template_40uwl2t', // Replace with your EmailJS template ID
        formData, // Pass form data directly
        'hiQgTpVo998k86DOL' // Replace with your EmailJS public key
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setSuccessMessage('Message sent successfully!');
          setErrorMessage('');
          // Reset form
          setFormData({
            from_name: '',
            from_email: '',
            position: '',
            company: '',
            message: '',
          });
        },
        (err) => {
          console.error('FAILED...', err);
          setErrorMessage('Failed to send message. Please try again later.');
          setSuccessMessage('');
        }
      );
  };

  return (
    <div className="contact-us-page">
      <video autoPlay loop muted className="heroVideo">
        <source
          src="https://res.cloudinary.com/dt98ksead/video/upload/v1731572384/3129957-hd_1280_720_25fps_upoccs.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <Container maxWidth="md" className="contact-us-container">
        <Typography variant="h4" className="contact-us-title">
          Get in Touch with Us
        </Typography>
        <Typography variant="body1" className="contact-us-description">
          We'd love to hear from you! Please fill out the form below, and our team will respond as soon as possible.
        </Typography>
        <form onSubmit={handleSubmit} className="contact-form">
          <Box className="form-row">
            <TextField
              fullWidth
              label="Name"
              name="from_name"
              value={formData.from_name}
              onChange={handleChange}
              required
              margin="normal"
              className="form-field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Email"
              name="from_email"
              type="email"
              value={formData.from_email}
              onChange={handleChange}
              required
              margin="normal"
              className="form-field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className="form-row">
            <TextField
              fullWidth
              label="Position"
              name="position"
              value={formData.position}
              onChange={handleChange}
              margin="normal"
              className="form-field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WorkIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              margin="normal"
              className="form-field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TextField
            fullWidth
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            multiline
            rows={4}
            margin="normal"
            className="form-field"
          />
          {successMessage && (
            <Typography variant="body2" color="success.main" mt={2}>
              {successMessage}
            </Typography>
          )}
          {errorMessage && (
            <Typography variant="body2" color="error.main" mt={2}>
              {errorMessage}
            </Typography>
          )}
          <Box textAlign="center" mt={4}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submit-button"
            >
              Send Message
            </Button>
          </Box>
        </form>
      </Container>
    </div>
  );
};

export default ContactUs;
