import React from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Container,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";

const UploadBlogPage = () => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      title: "",
      slug: "",
      rawContent: "", // Raw content to paste the blog
    },
  });

  const onSubmit = async (data) => {
    try {
      // Split content into title and sections (basic parsing)
      const [title, ...sections] = data.rawContent.split("\n\n");
      const blogData = {
        title: title.trim(),
        slug: data.slug.trim(),
        heroImage: data.heroImage.trim(),
        content: sections.map((section) => {
          if (/^\d+\.\s/.test(section)) {
            // Numbered sections (e.g., 1. Section Title)
            return {
              type: "subheading",
              text: section.trim(),
            };
          }
          return {
            type: "paragraph",
            text: section.trim(),
          };
        }),
      };

      // Post to the API
      const response = await axios.post("https://talented-charm-production.up.railway.app/api/blog", blogData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        alert("Blog uploaded successfully!");
        reset(); // Clear the form after successful submission
      } else {
        alert("Failed to upload blog.");
      }
    } catch (error) {
      console.error("Error uploading blog:", error.response?.data || error.message);
      alert("An error occurred while uploading the blog.");
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Upload Blog</title>
      </Helmet>
      <Typography variant="h4" sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }}>
        Upload New Blog
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Blog Slug */}
        <TextField
          {...register("slug", { required: true })}
          label="Slug (Unique URL Identifier)"
          variant="outlined"
          fullWidth
          sx={{ mb: 3 }}
        />

        {/* Hero Image URL */}
        <TextField
          {...register("heroImage")}
          label="Hero Image URL"
          variant="outlined"
          fullWidth
          sx={{ mb: 3 }}
        />

        {/* Blog Raw Content */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          Blog Content (Paste Entire Blog)
        </Typography>
        <TextField
          {...register("rawContent", { required: true })}
          label="Paste your blog content here"
          variant="outlined"
          multiline
          rows={15}
          fullWidth
          sx={{ mb: 3 }}
        />

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit Blog
        </Button>
      </form>
    </Container>
  );
};

export default UploadBlogPage;
