import React, { useEffect, useState } from "react";
import { Box, Typography, Container, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";

const BlogPage = () => {
  const { slug } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://talented-charm-production.up.railway.app/api/blog/${slug}`);
      // const response = await axios.get(`http://localhost:5000/api/blog/${slug}`);
        setBlogData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug]);

  if (loading) {
    return (
      <Box sx={{ position: "relative", minHeight: "100vh", overflow: "hidden" }}>
      {/* Background Video */}
      <video autoPlay loop muted className="heroVideo" style={{ position: "fixed", top: "0", left: "0", width: "100vw", height: "100vh", overflow: "hidden", objectFit: "cover", zIndex: '-1'}}>
        <source src="https://res.cloudinary.com/dt98ksead/video/upload/v1731572384/3129957-hd_1280_720_25fps_upoccs.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 40 }}>
        <CircularProgress />
      </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h5" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!blogData) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h5">Blog not available.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative", minHeight: "100vh", overflow: "hidden" }}>
      {/* Background Video */}
      <video autoPlay loop muted className="heroVideo" style={{ position: "fixed", top: "0", left: "0", width: "100vw", height: "100vh", overflow: "hidden", objectFit: "cover", zIndex: '-1'}}>
        <source src="https://res.cloudinary.com/dt98ksead/video/upload/v1731572384/3129957-hd_1280_720_25fps_upoccs.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          position: "relative",
          color: "white",
          backdropFilter: "blur(5px)",
          padding: "16px",
          borderRadius: "8px",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      >
        {/* Hero Image */}
        {blogData.heroImage && (
          <Box
            sx={{
              width: "100%",
              height: "300px",
              backgroundImage: `url(${blogData.heroImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "8px",
              mb: 4,
            }}
          ></Box>
        )}

        {/* Blog Title and Date */}
        <Typography variant="h3" sx={{ mb: 2, fontWeight: "bold" }}>
          {blogData.title}
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4 }}>
          {new Date(blogData.createdAt).toLocaleDateString()}
        </Typography>

        {/* Blog Content */}
        {blogData.content.map((section, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography
              variant={section.type === "subheading" ? "h2" : "body1"}
              sx={{
                mb: 2,
                fontWeight: section.type === "subheading" ? "bold" : "normal",
                fontSize: section.type === "subheading" ? "1.5rem" : "1rem",
                lineHeight: 1.8,
              }}
            >
              {section.text}
            </Typography>
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default BlogPage;
