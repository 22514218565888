import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Head from './components/Head';
import Footer from './components/Footer';
import Home from './pages/Home'; 
import AboutUs from './pages/AboutUs';
import OurTeam from './pages/OurTeam';
import ContactUs from './pages/ContactUs';
import CareersPage from './pages/CareersPage'; 
import ServicePageTemplate from './pages/ServicePageTemplate';
import ErrorPage from './pages/ErrorPage';
import BlogPage from './pages/BlogPage';
import AllBlogsPage from './pages/AllBlogsPage';
import UploadBlogPage from './pages/UploadBlogPage';
import services from './data/services';
import SEO from './components/SEO';

function App() {
  return (
    <Router>
      
      <Routes>
        <Route path="/" element={<><SEO {...services.home.seo} /><Head/><Home /></>} />
        <Route path="/about" element={<><SEO {...services.about.seo} /><Header /><AboutUs /></>} />
        <Route path='/careers' element={<><SEO {...services.careers.seo} /><Header /><CareersPage/></>}/>
        <Route path='/contact' element={<><SEO {...services.contact.seo} /><Header /><ContactUs/></>}/>
        <Route path='/team' element={<><SEO {...services.team.seo} /><Header /><OurTeam/></>}/>
        <Route path="/blogs" element={<><SEO {...services.blog.seo} /><Header /><AllBlogsPage /></>} />
        <Route path="/blogs/:slug" element={<><SEO {...services.blog.seo} /><Header /><BlogPage /></>} />
        <Route path="/upload" element={<UploadBlogPage />} />

        {/* Web Development */}
            <Route
            path="/customwebsite"
            element={
              <>
                <SEO {...services.customWebsite.seo} />
                <Head />
                <ServicePageTemplate
                  title={services.customWebsite.title}
                  description={services.customWebsite.description}
                  imageUrl={services.customWebsite.imageUrl}
                  benefits={services.customWebsite.benefits}
                  approach={services.customWebsite.approach}
                />
              </>
            }
          />

        <Route
          path="/ecommerce"
          element={
            <>
              <SEO {...services.ecommerce.seo} />
              <Head />
              <ServicePageTemplate
                title={services.ecommerce.title}
                description={services.ecommerce.description}
                imageUrl={services.ecommerce.imageUrl}
                benefits={services.ecommerce.benefits}
                approach={services.ecommerce.approach}
              />
            </>
          }
        />

        <Route
          path="/maintenance"
          element={
            <>
              <SEO {...services.websiteMaintenance.seo} />
              <Head />
              <ServicePageTemplate
                title={services.websiteMaintenance.title}
                description={services.websiteMaintenance.description}
                imageUrl={services.websiteMaintenance.imageUrl}
                benefits={services.websiteMaintenance.benefits}
                approach={services.websiteMaintenance.approach}
              />
            </>
          }
        />

        <Route
          path="/websiteupdates"
          element={
            <>
              <SEO {...services.websiteUpdates.seo} />
              <Head />
              <ServicePageTemplate
                title={services.websiteUpdates.title}
                description={services.websiteUpdates.description}
                imageUrl={services.websiteUpdates.imageUrl}
                benefits={services.websiteUpdates.benefits}
                approach={services.websiteUpdates.approach}
              />
            </>
          }
        />


        {/* App Development */}
        <Route
          path="/iosapps"
          element={
            <>
              <SEO {...services.iosApps.seo} />
              <Head />
              <ServicePageTemplate
                title={services.iosApps.title}
                description={services.iosApps.description}
                imageUrl={services.iosApps.imageUrl}
                benefits={services.iosApps.benefits}
                approach={services.iosApps.approach}
              />
            </>
          }
        />

        <Route
          path="/androidapps"
          element={
            <>
              <SEO {...services.androidApps.seo} />
              <Head />
              <ServicePageTemplate
                title={services.androidApps.title}
                description={services.androidApps.description}
                imageUrl={services.androidApps.imageUrl}
                benefits={services.androidApps.benefits}
                approach={services.androidApps.approach}
              />
            </>
          }
        />

        <Route
          path="/crossplatform"
          element={
            <>
              <SEO {...services.crossPlatformApps.seo} />
              <Head />
              <ServicePageTemplate
                title={services.crossPlatformApps.title}
                description={services.crossPlatformApps.description}
                imageUrl={services.crossPlatformApps.imageUrl}
                benefits={services.crossPlatformApps.benefits}
                approach={services.crossPlatformApps.approach}
              />
            </>
          }
        />

        <Route
          path="/desktopapps"
          element={
            <>
              <SEO {...services.desktopApps.seo} />
              <Head />
              <ServicePageTemplate
                title={services.desktopApps.title}
                description={services.desktopApps.description}
                imageUrl={services.desktopApps.imageUrl}
                benefits={services.desktopApps.benefits}
                approach={services.desktopApps.approach}
              />
            </>
          }
        />


        <Route
          path="/uiux"
          element={
            <>
              <SEO {...services.uiux.seo} />
              <Head />
              <ServicePageTemplate
                title={services.uiux.title}
                description={services.uiux.description}
                imageUrl={services.uiux.imageUrl}
                benefits={services.uiux.benefits}
                approach={services.uiux.approach}
              />
            </>
          }
        />

        <Route
          path="/branding"
          element={
            <>
              <SEO {...services.branding.seo} />
              <Head />
              <ServicePageTemplate
                title={services.branding.title}
                description={services.branding.description}
                imageUrl={services.branding.imageUrl}
                benefits={services.branding.benefits}
                approach={services.branding.approach}
              />
            </>
          }
        />

        <Route
          path="/graphicdesign"
          element={
            <>
              <SEO {...services.graphicDesign.seo} />
              <Head />
              <ServicePageTemplate
                title={services.graphicDesign.title}
                description={services.graphicDesign.description}
                imageUrl={services.graphicDesign.imageUrl}
                benefits={services.graphicDesign.benefits}
                approach={services.graphicDesign.approach}
              />
            </>
          }
        />

        <Route
          path="/elearnings"
          element={
            <>
              <SEO {...services.elearnings.seo} />
              <Head />
              <ServicePageTemplate
                title={services.elearnings.title}
                description={services.elearnings.description}
                imageUrl={services.elearnings.imageUrl}
                benefits={services.elearnings.benefits}
                approach={services.elearnings.approach}
              />
            </>
          }
        />


        <Route
          path="/dataanalysis"
          element={
            <>
              <SEO {...services.dataAnalysis.seo} />
              <Head />
              <ServicePageTemplate
                title={services.dataAnalysis.title}
                description={services.dataAnalysis.description}
                imageUrl={services.dataAnalysis.imageUrl}
                benefits={services.dataAnalysis.benefits}
                approach={services.dataAnalysis.approach}
              />
            </>
          }
        />

        <Route
          path="/visualization"
          element={
            <>
              <SEO {...services.dataVisualization.seo} />
              <Head />
              <ServicePageTemplate
                title={services.dataVisualization.title}
                description={services.dataVisualization.description}
                imageUrl={services.dataVisualization.imageUrl}
                benefits={services.dataVisualization.benefits}
                approach={services.dataVisualization.approach}
              />
            </>
          }
        />

        <Route
          path="/machinelearning"
          element={
            <>
              <SEO {...services.machineLearning.seo} />
              <Head />
              <ServicePageTemplate
                title={services.machineLearning.title}
                description={services.machineLearning.description}
                imageUrl={services.machineLearning.imageUrl}
                benefits={services.machineLearning.benefits}
                approach={services.machineLearning.approach}
              />
            </>
          }
        />

        <Route
          path="/businessintelligence"
          element={
            <>
              <SEO {...services.businessIntelligence.seo} />
              <Head />
              <ServicePageTemplate
                title={services.businessIntelligence.title}
                description={services.businessIntelligence.description}
                imageUrl={services.businessIntelligence.imageUrl}
                benefits={services.businessIntelligence.benefits}
                approach={services.businessIntelligence.approach}
              />
            </>
          }
        />


        <Route
          path="/socialmedia"
          element={
            <>
              <SEO {...services.digitalMarketing.seo} />
              <Head />
              <ServicePageTemplate
                title={services.digitalMarketing.title}
                description={services.digitalMarketing.description}
                imageUrl={services.digitalMarketing.imageUrl}
                benefits={services.digitalMarketing.benefits}
                approach={services.digitalMarketing.approach}
              />
            </>
          }
        />


        <Route
          path="/seo"
          element={
            <>
              <SEO {...services.seo.seo} />
              <Head />
              <ServicePageTemplate
                title={services.seo.title}
                description={services.seo.description}
                imageUrl={services.seo.imageUrl}
                benefits={services.seo.benefits}
                approach={services.seo.approach}
              />
            </>
          }
        />

      <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
