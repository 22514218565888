import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Pagination,
  Container,
  CircularProgress
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";  // Import Axios

const AllBlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const fetchBlogs = async (page = 1) => {
    setLoading(true);
    try {
      // Using axios instead of fetch and updating the URL to localhost
      // const response = await axios.get(`http://localhost:5000/api/blog?page=${page}&limit=6`);
    const response = await axios.get(`https://talented-charm-production.up.railway.app/api/blog?page=${page}&limit=6`);
      setBlogs(response.data.blogs);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Failed to fetch blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs(currentPage);
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchBlogs(value);
  };

  if (loading) {
    return (
      <Box sx={{ position: "relative", minHeight: "100vh", overflow: "hidden" }}>
      {/* Background Video */}
      <video autoPlay loop muted className="heroVideo" style={{ position: "fixed", top: "0", left: "0", width: "100vw", height: "100vh", overflow: "hidden", objectFit: "cover", zIndex: '-1'}}>
        <source src="https://res.cloudinary.com/dt98ksead/video/upload/v1731572384/3129957-hd_1280_720_25fps_upoccs.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 40 }}>
        <CircularProgress />
      </Box>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
        {/* Background Video */}
      <video autoPlay loop muted className="heroVideo" style={{ position: "fixed", top: "0", left: "0", width: "100vw", height: "100vh", overflow: "hidden", objectFit: "cover", zIndex: '-1'}}>
        <source src="https://res.cloudinary.com/dt98ksead/video/upload/v1731572384/3129957-hd_1280_720_25fps_upoccs.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Typography variant="h4" sx={{ mb: 4, fontWeight: "bold", textAlign: "center", color: "white" }}>
        Explore
      </Typography>

      {/* Blog Cards */}
      <Grid container spacing={4}>
        {blogs.map((blog) => (
          <Grid item xs={12} sm={6} md={4} key={blog.slug}>
            <Card sx={{ height: "100%", display: "flex", flexDirection: "column", border: "2px solid #ccc", borderRadius: "10px" }}>
              {/* Hero Image */}
              {blog.heroImage && (
                <CardMedia
                  component="img"
                  height="140"
                  image={blog.heroImage}
                  alt={blog.title}
                />
              )}
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {blog.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {blog.content[0]?.text.slice(0, 100)}...
                </Typography>
              </CardContent>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ p: 2 }}>
                <Button
                  component={Link}
                  to={`/blogs/${blog.slug}`}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Read More
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4, color: "white" }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Container>
  );
};

export default AllBlogsPage;
