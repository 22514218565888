import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import './CareersPage.css';

const CareersPage = () => {
  const [selectedJob, setSelectedJob] = useState(null);

  const jobListings = [
    {
      title: 'Sales Associate',
      location: 'Johar Town, Lahore (2 pm to 10 pm)',
      summary: 'We are seeking ambitious Sales Associates to conduct outbound calls and generate leads from international markets.',
      responsibilities: [
        'Make outbound calls to potential clients',
        'Qualify leads and gather relevant information about client needs',
        'Schedule meetings and demos for the Senior Sales Associate',
        'Track and report on lead generation and qualification results',
        'Support marketing efforts with insights and feedback on target market'
      ],
      qualifications: [
        'Strong verbal communication and persuasion skills',
        'Ability to quickly build rapport over the phone',
        'Self-motivated and goal-oriented with a team mindset',
        'Experience in outbound sales or a call center environment is a plus',
        'Female candidates are encouraged to apply'
      ]
    },
    {
      title: 'Project Manager',
      location: 'Johar Town, Lahore (2 pm to 10 pm)',
      summary: 'We are searching for an experienced Project Manager with a programming background to oversee client projects, manage the development team, and ensure timely delivery.',
      responsibilities: [
        'Manage and coordinate all aspects of client projects, from planning to execution',
        'Act as the primary technical advisor and problem-solver for the development team',
        'Communicate with clients to gather requirements, provide updates, and ensure satisfaction',
        'Conduct code reviews, ensure best practices, and troubleshoot development issues',
        'Maintain project documentation, timelines, and budget adherence'
      ],
      qualifications: [
        '3+ years of experience in software development, with project management experience',
        'Proficiency in the MERN stack and familiarity with WordPress is advantageous',
        'Strong organizational, communication, and leadership skills',
        'Proven ability to manage multiple projects and meet deadlines',
        'Experience with project management tools (e.g., Clickup) is a plus'
      ]
    }
  ];

  const handleJobClick = (job) => {
    setSelectedJob(job);
  };

  const closeModal = () => {
    setSelectedJob(null);
  };

  return (
    <div className="careers-page">
      <video autoPlay loop muted className="heroVideo">
          <source src="https://res.cloudinary.com/dt98ksead/video/upload/v1731572384/3129957-hd_1280_720_25fps_upoccs.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      <h1>Careers at Arc Codes</h1>
      <p>Are you passionate about tech, creativity, and collaboration? At Arc Codes, we're building more than just websites and apps—we're crafting solutions, experiences, and a place where you can grow.</p>

      <div className="job-listings">
        {jobListings.map((job, index) => (
          <div key={index} className="job-listing" onClick={() => handleJobClick(job)}>
            <h2>{job.title}</h2>
            <p>{job.location}</p>
            <button>Learn More</button>
          </div>
        ))}
      </div>

      {selectedJob && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>X</button>
            <h2>{selectedJob.title}</h2>
            <p><strong>Location:</strong> {selectedJob.location}</p>
            <p><strong>Job Summary:</strong> {selectedJob.summary}</p>
            <h3>Responsibilities:</h3>
            <ul>
              {selectedJob.responsibilities.map((resp, index) => (
                <li key={index}>{resp}</li>
              ))}
            </ul>
            <h3>Qualifications:</h3>
            <ul>
              {selectedJob.qualifications.map((qual, index) => (
                <li key={index}>{qual}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div className="why-arc-codes">
        <h2>Why Arc Codes?</h2>
        <ul>
          <li>Innovative Projects: Work with the latest technologies, exciting clients, and projects that challenge and inspire you.</li>
          <li>Culture: At Arc Codes, you're more than just an employee—you're part of our family. Our inclusive work culture helps you thrive, learn, and grow.</li>
          <li>Great Work-Life Balance: We believe in flexibility and a positive work environment. At Arc Codes, your growth and happiness are our top priorities.</li>
        </ul>
      </div>

      <div className="culture-images">
        <p>See what life at Arc Codes is like through our photos below!</p>
        <div className="images-grid">
          <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1733142380/DSC05536-scaled_nvveuj.jpg" alt="Team collaboration" />
          <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1735304737/arc1_ahp7wy.jpg" alt="Team event" />
          <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1733142406/image00012-1_bdsjpg.jpg" alt="Office fun" />
          <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1735726633/2ad11ac8-e839-4c91-bf58-a9505fca8727.png" alt="Team event" />
          <img src="https://res.cloudinary.com/dt98ksead/image/upload/c_crop,ar_1:1/v1733144076/WhatsApp_Image_2024-12-02_at_17.04.23_da1cbae5_zmlrj8.jpg" alt="Team event" />
          <img src="https://res.cloudinary.com/dt98ksead/image/upload/v1735305956/0ecb8ad1-4b1c-40c6-baac-9db3b98d48f2.png" alt="Team event" />
        </div>
      </div>

      <div className="contact-section">
        <h2>Interested in joining us?</h2>
        <p><span className="email-icon">📧</span> Send your resume to <a href="mailto:admin@arccodes.com">admin@arccodes.com</a></p>
      </div>
    </div>
  );
};

export default CareersPage;
