import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { motion } from 'framer-motion';
import './ServicePageTemplate.css';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DevicesIcon from '@mui/icons-material/Devices';
import PeopleIcon from '@mui/icons-material/People';


const ServicePageTemplate = ({ title, description, imageUrl, benefits, approach }) => {
  const relatedServices = [
    {
      title: 'Digital Marketing',
      shortDescription: 'Boost your brand presence with our social media and digital marketing experts.',
      imageUrl: 'https://res.cloudinary.com/dt98ksead/image/upload/v1736182085/61dd5db5-9ae4-4f42-b1b3-6999def11bc2.png',
      link: '/socialmedia'
    },
    {
      title: 'SEO Services',
      shortDescription: 'Improve your website\'s visibility on search engines and drive organic traffic.',
      imageUrl: 'https://res.cloudinary.com/dt98ksead/image/upload/v1736182219/977876f7-6063-4386-a223-5c613d3c7fe8.png',
      link: '/seo'
    },
    {
      title: 'UI/UX Design',
      shortDescription: 'Create intuitive, engaging, and user-friendly interfaces that delight your users.',
      imageUrl: 'https://res.cloudinary.com/dt98ksead/image/upload/v1736178433/12869afe-8f07-4f7c-8578-2411c9cb3e6e.png',
      link: '/uiux'
    }
  ];

  // Remove the current service from related services
  const filteredRelatedServices = relatedServices.filter(service => service.title !== "title");
  return (
    
    
    <Container className='service-page-template' style={{padding: "0 0 0 0"}}>

        <video autoPlay loop muted className="heroVideo">
          <source src="https://res.cloudinary.com/dt98ksead/video/upload/v1731572384/3129957-hd_1280_720_25fps_upoccs.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      {/* Hero Section */}
      <Box className="hero-section" style={{ backgroundImage: `url(${imageUrl})` }}>
        <Box className="hero-content">
          <Typography variant="h2" component="h1" gutterBottom sx={{color: "white"}}>
            {title}
          </Typography>
          <Typography variant="h5">{description}</Typography>
        </Box>
      </Box>

      {/* Benefits Section */}
      <Box padding={4} className="benefits-section">
        <Typography variant="h4" component="h2" gutterBottom align="center" sx={{color: "white", margin:"3rem"}}>
          Why Choose Our Services
        </Typography>

        <Grid container spacing={20} justifyContent="center">
          {benefits.map((benefit, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} className='benifit-card-parent'>
              <motion.div whileHover={{ scale: 1.1 }} className="benefit-card" >
                <Card className="benefit-card-content" sx={{borderRadius: '25px'}}>
                  <CardMedia
                    component="img"
                    alt={`Benefit ${index + 1}`}
                    height="200"
                    image={benefit.imageUrl}
                  />
                  <CardContent>
                    <Typography variant="h6" component="h3">
                      {benefit.title}
                    </Typography>
                    <Typography variant="body2">
                      {benefit.description}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
      
      <Box className="approach-section" sx={{padding: "3rem 1.5rem" }}>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{ color: "white", marginBottom: "2rem" }}
        align="center"
      >
        Our Approach
      </Typography>
      <Grid container spacing={4} alignItems="center">
        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box className="approach-image" sx={{ textAlign: "center" }}>
            <img
              src="https://res.cloudinary.com/dt98ksead/image/upload/v1736170282/56d49e01-46d3-47d6-b6b9-0c072ddb734f.png"
              alt="UI/UX Design"
              style={{ maxWidth: "100%", borderRadius: "25px", border: "3px solid #f0f0f0" }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="approach-txt" sx={{ color: "white" }}>
            <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: "1.5rem" }}>
              <DesignServicesIcon sx={{ fontSize: "4rem", marginRight: "1rem", color: "#4caf50" }} />
              <Box sx={{justifyContent: "center"}}>
                <Typography variant="h6" gutterBottom>{approach[0].title}</Typography>
                <Typography variant="body1">
                {approach[0].description}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "flex-start", marginBottom: "1.5rem" }}>
              <DevicesIcon sx={{ fontSize: "4rem", marginRight: "1rem", color: "#2196f3" }} />
              <Box sx={{justifyContent: "center"}}>
                <Typography variant="h6" gutterBottom>{approach[1].title}</Typography>
                <Typography variant="body1">
                {approach[1].description}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <PeopleIcon sx={{ fontSize: "4rem", marginRight: "1rem", color: "#ff5722" }} />
              <Box sx={{justifyContent: "center"}}>
                <Typography variant="h6" gutterBottom>{approach[2].title}</Typography>
                <Typography variant="body1">
                {approach[2].description}                
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>

    
      {/* Our Process Section */}
      <Box className="process-section">
        <Typography variant="h4" component="h2" gutterBottom sx={{color: "white", margin:"3rem"}}>
          Our Development Lifecycle
        </Typography>
        <Typography variant="body1" paragraph>
          We have crafted a meticulous and proven process to bring your vision to life, ensuring that each phase is handled with the utmost care and precision.
        </Typography>
        <Grid container spacing={15} justifyContent="center">
          {[{
            step: 'Discovery',
            description: 'We start with understanding your business goals, audience, and unique value proposition to tailor a solution just for you.',
            imageUrl: 'https://res.cloudinary.com/dt98ksead/image/upload/v1736164719/305b40af-0f47-48fd-a216-d92f871f0be5.png'
          }, {
            step: 'Planning',
            description: 'A detailed plan is crafted, complete with timelines and milestones, ensuring all requirements are addressed.',
            imageUrl: 'https://res.cloudinary.com/dt98ksead/image/upload/v1736165218/784d13b5-35d9-49b0-8ff3-41ad7b9b2a05.png'
          }, {
            step: 'Design',
            description: 'Our creative team designs visually stunning and user-friendly interfaces that resonate with your brand identity.',
            imageUrl: 'https://res.cloudinary.com/dt98ksead/image/upload/v1736165371/3853007b-4879-4a61-a68d-c10d7b23c865.png'
          }, {
            step: 'Development',
            description: 'Our team of experts carry out your requirements, with an emphasis on performance, and scalability',
            imageUrl: 'https://res.cloudinary.com/dt98ksead/image/upload/v1736164772/7163fa04-fa22-4dfe-a871-77f4cc50d4f5.png'
          }, {
            step: 'Testing',
            description: 'Rigorous testing is performed to ensure you get what you want. Your satisfaction is our key priority',
            imageUrl: 'https://res.cloudinary.com/dt98ksead/image/upload/v1736165309/96e79d5d-6225-4965-8d35-9406485b1621.png'
          }, {
            step: 'Launch',
            description: 'We launch your product with full support, ensuring a smooth transition and immediate impact.',
            imageUrl: 'https://images.pexels.com/photos/796206/pexels-photo-796206.jpeg?auto=compress&cs=tinysrgb&w=600'
          }].map((process, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div whileHover={{ scale: 1.05 }}>
                <Card className="service-card" sx={{borderRadius: '25px'}}>
                  <CardMedia
                    component="img"
                    alt={process.step}
                    height="200"
                    image={process.imageUrl}
                  />
                  <CardContent>
                    <Typography variant="h6" component="h3">
                      {process.step}
                    </Typography>
                    <Typography variant="body2">
                      {process.description}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Related Services Section */}
      <Box className="related-services-section">
        <Typography variant="h4" component="h2" gutterBottom sx={{color: "white", margin:"3rem"}}>
          Other Services You Might Be Interested In
        </Typography>
        <Grid container spacing={20} justifyContent="center">
          {filteredRelatedServices.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div whileHover={{ scale: 1.05 }}>
                <Link to={service.link} style={{ textDecoration: 'none' }} onClick={() => {window.scrollTo(0, 0);}}>
                <Card className="service-card" sx={{borderRadius: '25px'}}>
                  <CardMedia
                    component="img"
                    alt={service.title}
                    height="200"
                    image={service.imageUrl}
                  />
                  <CardContent>
                    <Typography variant="h6" component="h3">
                      {service.title}
                    </Typography>
                    <Typography variant="body2">{service.shortDescription}</Typography>
                  </CardContent>

                </Card>
                </Link>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default ServicePageTemplate;

