const services = {
    customWebsite: {
        title: "Custom Websites",
        description: "Create a stunning, responsive, and SEO-optimized website tailored to your unique business needs...",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735295462/custom_wide_geqqsb.webp",
        benefits: [
            { title: "Responsive Design", description: "Your website will look great on any device no matter the screen size.", imageUrl: "https://images.pexels.com/photos/4158/apple-iphone-smartphone-desk.jpg?auto=compress&cs=tinysrgb&w=600" },
            { title: "SEO Optimization", description: "Get found on search engines with our built-in SEO strategies.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736170557/5bee4524-9b49-45c2-bb5f-7f6cceddca65.png" },
            { title: "Custom Solutions", description: "We tailor the features to match your unique business needs.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736170457/96350350-962c-4c07-82bf-181ac447060e.png" },
        ],
        approach: [
            { title: "Custom Website Design Services", description: "Our custom website design services are tailored to reflect your unique brand identity..." },
            { title: "Responsive and Mobile-Friendly Websites", description: "We design responsive, mobile-friendly websites that deliver seamless performance on all devices..." },
            { title: "Scalable and SEO-Optimized Solutions", description: "Our websites are built with scalability and SEO optimization in mind, ensuring they grow with your business..." },
        ],
        seo: {
            title: "Custom Website Design and Development Services | Arc Codes",
            description: "Get a stunning, SEO-optimized, and mobile-friendly custom website tailored to your business needs with Arc Codes. Enhance your online presence and user engagement.",
            keywords: "custom website design, SEO-friendly websites, mobile responsive web design, tailored business websites, scalable website development, arc codes",
            ogTitle: "Custom Website Design Tailored for Your Business Success | Arc Codes",
            ogDescription: "Design a unique, SEO-optimized website with Arc Codes that reflects your brand identity. Boost user engagement with responsive and scalable web solutions.",
            ogImage: "https://example.com/custom-website-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@webdesign_pros"
        },
                 
    },
    ecommerce: {
        title: "E-Commerce Development",
        description: "Launch your online store with a robust, user-friendly, and secure e-commerce platform...",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735298285/997c94f9-0c6e-4026-baa1-eac4e3d5651d.png",
        benefits: [
            { title: "Secure Payments", description: "Integrated payment gateways ensure secure and seamless transactions.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736169978/73a60dfb-bede-473b-9011-191fe91b8fc2.png" },
            { title: "Product Management", description: "Easily manage your products, inventory, and orders.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736164582/54fa0f58-73fb-4fe9-932f-aa637fb7200d.png" },
            { title: "Customizable Storefronts", description: "Design a storefront that reflects your brand and engages customers.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736170118/1665d4d0-cf2a-45cd-a1b2-63b4ee263d0d.png" },
        ],
        approach: [
            { title: "Custom E-Commerce Website Development", description: "We create custom e-commerce websites that are tailored to your brand..." },
            { title: "Mobile-Optimized E-Commerce Solutions", description: "Our e-commerce websites are mobile-optimized to cater to the growing number of shoppers..." },
            { title: "Scalable and SEO-Driven E-Commerce Platforms", description: "We develop scalable e-commerce platforms equipped with advanced features..." },
        ],
        seo: {
            title: "Custom E-Commerce Development Services | Arc Codes",
            description: "Launch a feature-rich online store with Arc Codes, offering secure payments, responsive design, and seamless user experience to boost sales and grow your business.",
            keywords: "custom ecommerce development, secure payment gateway integration, online store solutions, responsive ecommerce platforms, scalable ecommerce websites, arc codes, ecommerce with arc codes, arc codes ecommerce solutions",
            ogTitle: "Build and Grow Your Online Store with Expert E-Commerce Development | Arc Codes",
            ogDescription: "Create a powerful and user-friendly e-commerce platform with Arc Codes. From product management to seamless transactions, we’ve got you covered.",
            ogImage: "https://example.com/ecommerce-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@ecommerce_experts"
        },
                 
    },
    websiteMaintenance: {
        title: "Website Maintenance",
        description: "Ensure your website runs smoothly with regular updates, performance optimization, and proactive monitoring to provide a seamless user experience and maintain top-notch security",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735298378/d276dc61-3473-4845-a982-9f45440e02d3.png",
        benefits: [
            { title: "Security Updates", description: "Keep your website secure with the latest patches and updates.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736170710/112535f6-1cf3-4110-9a41-8075627fa77c.png" },
            { title: "Performance Optimization", description: "Ensure fast load times and high performance.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736175296/af735286-5b06-4992-9c2d-21d231348b65.png" },
            { title: "Content Updates", description: "Update your website's content regularly to stay relevant.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736178019/37b5621d-4148-458c-b50a-915c4c5f94cd.png" },
        ],
        approach: [
            { title: "Comprehensive Website Maintenance Services", description: "Our website maintenance services ensure your site remains secure, up-to-date, and fully functional..." },
            { title: "Enhanced Security and Monitoring", description: "We provide advanced website security solutions, including malware scanning and threat monitoring..." },
            { title: "Performance Optimization and SEO Updates", description: "Our maintenance plans include performance optimization to improve load times and user experience..." },
        ],
        seo: {
            title: "Reliable Website Maintenance Services | Arc Codes",
            description: "Ensure your website performs optimally with Arc Codes, offering security updates, performance monitoring, and regular content management tailored to your needs.",
            keywords: "website maintenance services, secure website updates, performance optimization for websites, regular website backups, professional website monitoring, arc codes, arc codes website maintenance, reliable website care by arc codes",
            ogTitle: "Keep Your Website Running Smoothly with Expert Maintenance | Arc Codes",
            ogDescription: "Protect your website with ongoing updates, enhanced security, and proactive performance optimization provided by Arc Codes.",
            ogImage: "https://example.com/website-maintenance-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@website_care"
        },
                 
    },
    websiteUpdates: {
        title: "Website Updates",
        description: "Keep your website fresh, engaging, and SEO-friendly with timely updates, new content, and feature enhancements that captivate your audience and boost search engine rankings.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735298529/59bba01a-29d9-45af-b244-3ee6a4e669ab.png",
        benefits: [
            { title: "Design Enhancements", description: "Refresh your website design to match current trends.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736178097/7985aa60-7e9a-4346-9436-68c70fef1532.png" },
            { title: "Feature Additions", description: "Add new features to keep your website competitive.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736178180/f9906989-cc7b-4c8b-a0c7-629131398abd.png" },
            { title: "Bug Fixes", description: "Resolve any issues quickly to maintain functionality.", imageUrl: "https://images.pexels.com/photos/11035537/pexels-photo-11035537.jpeg?auto=compress&cs=tinysrgb&w=600" },
        ],
        approach: [
            { title: "Professional Website Updates", description: "Keep your website fresh and relevant with our professional website update services..." },
            { title: "Custom Website Redesign Services", description: "Transform your online presence with our custom website redesign services..." },
            { title: "SEO-Focused and Conversion-Driven Redesigns", description: "Our website redesigns are optimized for search engines and conversions..." },
        ],
        seo: {
            title: "Professional Website Updates and Enhancements | Arc Codes",
            description: "Keep your website fresh, SEO-friendly, and user-focused with Arc Codes. We provide timely design updates, feature additions, and performance optimizations to enhance your online presence.",
            keywords: "website update services, SEO-friendly website updates, design enhancements for websites, feature updates for websites, website content refresh, arc codes, arc codes website updates, website enhancements by arc codes",
            ogTitle: "Boost Engagement with Professional Website Updates | Arc Codes",
            ogDescription: "Ensure your website stays relevant and competitive with Arc Codes, offering regular updates, SEO improvements, and engaging new features tailored to your needs.",
            ogImage: "https://example.com/website-updates-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@web_updates_team"
        },
                 
    },
    iosApps: {
        title: "iOS App Development",
        description: "Build elegant, feature-rich, and user-friendly iOS apps designed to captivate your audience, enhance engagement, and deliver seamless performance across Apple devices.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735298616/a1e0a4e7-1955-49af-9194-1e9a69172e7a.png",
        benefits: [
            { title: "App Store Deployment", description: "Seamlessly launch your app on the App Store.", imageUrl: "https://images.pexels.com/photos/1294886/pexels-photo-1294886.jpeg?auto=compress&cs=tinysrgb&w=600" },
            { title: "User-Centric Design", description: "Intuitive and user-friendly interfaces.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736178334/abc5c576-9652-426f-80c0-eb64600245dc.png" },
            { title: "Custom Functionality", description: "Features tailored to your business needs.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736175468/ffc9d375-29bf-4ffa-a8cf-6db06420b909.png" },
        ],
        approach: [
            { title: "Custom iOS Application Development", description: "We specialize in building custom iOS applications tailored to your unique business needs..." },
            { title: "Native iOS App Development Services", description: "Our native iOS app development services ensure optimized performance and full compatibility..." },
            { title: "App Store Optimization and Scalability", description: "We focus on developing scalable iOS apps that grow with your business..." },
        ],
        seo: {
            title: "Custom iOS App Solutions | Arc Codes",
            description: "Expert iOS app development by Arc Codes, delivering tailored mobile solutions to boost user engagement and streamline business operations.",
            keywords: "custom iOS apps, tailored mobile development, Apple app solutions, business mobile apps, iOS developers for hire, arc codes, arc codes iOS app development, custom mobile apps by arc codes",
            ogTitle: "Tailored iOS App Development Services | Arc Codes",
            ogDescription: "Build custom iOS applications with Arc Codes, designed to drive user engagement and enhance your business growth.",
            ogImage: "https://example.com/ios-app-development-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@iosapps_experts"
        },
                 
    },
    androidApps: {
        title: "Android App Development",
        description: "Create robust, scalable, and user-friendly Android apps tailored to elevate your business, enhance user engagement, and deliver exceptional performance on all Android devices.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735298726/533b1b0e-e785-4101-9c6a-f03871b5ecf2.png",
        benefits: [
            { title: "Play Store Deployment", description: "Launch your app successfully on the Google Play Store.", imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpau4h1tEZPE9hnkfnYanSN8Xafe6w6Oqctw&s" },
            { title: "Custom Integrations", description: "Integrate third-party services seamlessly.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736175468/ffc9d375-29bf-4ffa-a8cf-6db06420b909.png" },
            { title: "High Performance", description: "Apps optimized for speed and usability.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736175576/7c8d3e9d-9a52-498e-bb2e-c925f46aff3d.png" },
        ],
        approach: [
            { title: "Custom Android App Development", description: "We build custom Android applications tailored to your business needs, creating feature-rich, user-friendly apps that deliver seamless performance across all Android devices and align with your brand identity." },
            { title: "Native Android Development Services", description: "Our native Android development services ensure optimized performance and compatibility across the Android ecosystem. Using the latest tools and technologies, we deliver apps with superior user experiences and reliability." },
            { title: "Google Play Optimization and Scalability", description: "We develop scalable Android apps that grow with your business. Our team ensures your app is Google Play-ready with optimized metadata, sleek designs, and advanced features to enhance discoverability and user engagement." }
        ],
        seo: {
            title: "Custom Android App Development | Arc Codes",
            description: "Develop high-performance, scalable Android apps with Arc Codes, tailored to your business needs, ensuring exceptional user experience and growth.",
            keywords: "Android app development experts, scalable Android apps, custom Android software, Play Store apps development, Android app developers, arc codes, arc codes Android app development, custom Android solutions by arc codes",
            ogTitle: "Professional Android App Development Services | Arc Codes",
            ogDescription: "Custom Android applications built by Arc Codes to elevate your brand and enhance user engagement.",
            ogImage: "https://example.com/android-app-development-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@androidapps_team"
        },
                 
    },
    crossPlatformApps: {
        title: "Cross-Platform App Development",
        description: "Deliver high-performing, seamless apps optimized for Android, iOS, and other platforms, ensuring a consistent user experience and broader reach for your business.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735298904/6deed17c-df6f-46bd-a775-5585d9cfc1ad.png",
        benefits: [
            { title: "Cost Efficiency", description: "Develop once, deploy everywhere to save time and cost.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736177727/6c8cde54-522c-4c6b-b0a1-a63918979c4e.png" },
            { title: "Unified Experience", description: "Ensure consistent UI/UX across devices.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736177689/98e0e1b7-95d5-4dbf-8450-7b93b3d42912.png" },
            { title: "Quick Deployment", description: "Launch faster with efficient development cycles.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736177798/89d4177c-fbb0-4f0e-ab07-1b88f2c4896d.png" },
        ],
        approach: [
            { title: "Custom Cross-Platform App Development", description: "We create custom cross-platform applications that deliver seamless performance on both iOS and Android devices. Our apps are feature-rich, user-friendly, and designed to maximize reach and user engagement." },
            { title: "Efficient and Cost-Effective Solutions", description: "Our cross-platform development services leverage frameworks like Flutter and React Native to build apps faster and reduce costs without compromising on quality or functionality." },
            { title: "Scalable and App Store-Ready", description: "We ensure your cross-platform app is scalable and optimized for both the Apple App Store and Google Play. With sleek designs, robust functionality, and optimized metadata, your app is set for success across platforms." }
        ],
        seo: {
            title: "Cross-Platform App Experts | Arc Codes",
            description: "Develop seamless cross-platform applications with Arc Codes that deliver consistent performance and maximize audience reach across iOS and Android.",
            keywords: "cross-platform mobile apps, Flutter app development, React Native solutions, unified app experiences, cost-effective mobile development, arc codes, arc codes cross-platform apps, cross-platform solutions by arc codes",
            ogTitle: "Expert Cross-Platform App Development | Arc Codes",
            ogDescription: "Create apps with Arc Codes that work flawlessly across iOS and Android using cost-effective cross-platform solutions.",
            ogImage: "https://example.com/cross-platform-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@crossplatform_dev"
        },
                 
    },
    desktopApps: {
        title: "Desktop App Development",
        description: "Build powerful, efficient, and custom desktop applications designed to streamline your business operations, boost productivity, and meet your unique organizational needs.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735299032/91090e0d-5b75-4f12-a639-e6d3c2090766.png",
        benefits: [
            { title: "Cross-Platform Compatibility", description: "Develop apps for Windows, macOS, and Linux.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736177438/4d4a9625-fc3f-47ff-857f-5cb4e9f23885.png" },
            { title: "Custom Interfaces", description: "Create tailored designs to optimize workflow.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180703/17f16662-6126-43ee-983d-c23a09f4fa3a.png" },
            { title: "Scalable Solutions", description: "Future-proof apps to grow with your business.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180534/0e591786-0165-4ae4-8350-f8c6a2c55909.png" },
        ],
        approach: [
            { title: "Custom Desktop Application Development", description: "We specialize in custom desktop application development to create powerful, feature-rich software tailored to your business needs. Whether it's for Windows, macOS, or Linux, our desktop apps deliver exceptional performance and reliability." },
            { title: "Cross-Platform Desktop App Solutions", description: "Our cross-platform desktop app development services ensure your software runs seamlessly across multiple operating systems. By leveraging technologies like Electron and .NET, we deliver high-quality, user-friendly applications with consistent functionality." },
            { title: "Scalable, Secure, and Optimized for Performance", description: "We develop scalable desktop applications with robust security features and optimized performance. Our solutions are designed to grow with your business while ensuring your software remains secure, fast, and user-centric." }
        ],
        seo: {
            title: "Custom Desktop Application Development | Arc Codes",
            description: "Streamline your operations with Arc Codes, offering powerful, cross-platform desktop applications tailored to your business needs for maximum efficiency and scalability.",
            keywords: "desktop app development services, cross-platform desktop apps, custom software for business, scalable desktop solutions, secure desktop applications, arc codes, arc codes desktop app development, custom desktop solutions by arc codes",
            ogTitle: "Efficient and Scalable Desktop Applications | Arc Codes",
            ogDescription: "Build custom desktop applications with Arc Codes to enhance productivity, streamline workflows, and support business growth across all major platforms.",
            ogImage: "https://example.com/desktop-apps-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@custom_apps_team"
        },
                 
    },

    uiux: {
        title: "UI/UX Design",
        description: "Design intuitive, visually stunning, and user-centric interfaces that deliver exceptional user experiences and keep your audience engaged.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735299134/74a8913e-38c9-400f-8fd0-0e3e81d33ff3.png",
        benefits: [
            { 
                title: "User Research", 
                description: "Understand your audience for data-driven designs.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736178334/abc5c576-9652-426f-80c0-eb64600245dc.png" 
            },
            { 
                title: "Prototyping", 
                description: "Visualize designs with clickable prototypes.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736178301/bc4e5736-6c1f-4381-a4b7-1f392bc734d4.png" 
            },
            { 
                title: "Modern Aesthetics", 
                description: "Leverage cutting-edge design trends.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736178433/12869afe-8f07-4f7c-8578-2411c9cb3e6e.png" 
            },
        ],
        approach: [
            { 
                title: "Custom UI/UX Design Services", 
                description: "We provide custom UI/UX design services to create visually stunning, user-centric interfaces that enhance user engagement and satisfaction. Our designs align with your brand identity while ensuring intuitive navigation and functionality." 
            },
            { 
                title: "Mobile and Web UI/UX Solutions", 
                description: "Our UI/UX design solutions cater to both mobile and web platforms, delivering responsive and interactive designs that work seamlessly across all devices. By prioritizing user experience, we help drive higher conversions and retention." 
            },
            { 
                title: "Research-Driven and Scalable Designs", 
                description: "We use research-driven methodologies to understand user behavior and create designs that resonate with your audience. Our scalable solutions ensure your UI/UX adapts to evolving business needs and technological advancements." 
            },
        ],
        seo: {
            title: "User-Focused UI/UX Design | Arc Codes",
            description: "Transform your digital presence with Arc Codes, delivering intuitive and user-friendly UI/UX designs that enhance engagement and boost conversions.",
            keywords: "custom UI/UX design, mobile UX solutions, website design services, user-friendly interfaces, intuitive digital design, arc codes, arc codes UI/UX design, user-centric design by arc codes",
            ogTitle: "Creative UI/UX Design Services | Arc Codes",
            ogDescription: "Build visually appealing and user-centric interfaces with Arc Codes for your mobile and web platforms.",
            ogImage: "https://example.com/ui-ux-design-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@uiuxdesign_team"
        },
                
    },
    
    branding: {
        title: "Branding",
        description: "Develop a compelling, cohesive, and memorable brand identity that resonates with your audience, strengthens your market presence, and sets you apart from the competition.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735299232/adb7c16b-65a1-4a61-a3bd-939fd172e710.png",
        benefits: [
            { 
                title: "Logo Design", 
                description: "Create memorable logos that define your brand.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736178845/images_nhprud.jpg" 
            },
            { 
                title: "Brand Strategy", 
                description: "Develop a strategic plan to communicate your values.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736178601/2124361a-0ab3-4c7c-82b6-5dd7f46cc62f.png" 
            },
            { 
                title: "Marketing Collateral", 
                description: "Consistent designs for business cards, brochures, and more.", 
                imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTPCYoMS4p47AXU_MCoKPuYy7NCts3oRuosg&s" 
            },
        ],
        approach: [
            { 
                title: "Custom Branding Solutions", 
                description: "We provide custom branding solutions that establish a strong and consistent identity for your business. From logos to brand guidelines, we craft visually appealing assets that resonate with your target audience and set you apart." 
            },
            { 
                title: "Comprehensive Brand Strategy Development", 
                description: "Our brand strategy services focus on building a cohesive and impactful brand presence. Through market research and competitor analysis, we define your unique value proposition and messaging to strengthen your brand’s market position." 
            },
            { 
                title: "Digital and Print Branding Expertise", 
                description: "We deliver branding solutions optimized for both digital and print platforms. From social media branding to business collateral, our designs ensure consistency, visibility, and recognition across all touchpoints." 
            },
        ],
        seo: {
            title: "Strategic Branding Solutions | Arc Codes",
            description: "Build a powerful brand identity with Arc Codes, offering custom logo designs, strategic branding plans, and consistent marketing materials to elevate your business.",
            keywords: "custom branding solutions, professional logo design, strategic brand identity, business marketing collateral, impactful branding services, arc codes, arc codes branding solutions, branding services by arc codes",
            ogTitle: "Custom Branding Solutions for Businesses | Arc Codes",
            ogDescription: "Create a cohesive brand identity with Arc Codes that resonates with your target audience and drives market presence.",
            ogImage: "https://example.com/branding-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@branding_gurus"
        },
                 
    },
    
    graphicDesign: {
        title: "Graphic Design",
        description: "Bring your ideas to life with stunning visuals and engaging designs that captivate your audience, communicate your message, and elevate your brand's impact.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735299315/e84d158d-7ffb-4203-a416-3071bd8cbcee.png",
        benefits: [
            { 
                title: "Infographics", 
                description: "Simplify complex data with visually appealing graphics.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180997/1bb546b4-dfcb-49ef-adfc-d4e6f36fc276.png" 
            },
            { 
                title: "Custom Illustrations", 
                description: "Stand out with unique and creative visuals.", 
                imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXUsIXQ8y9xu9r_I1DCy4C9-Bf-oo7IVWUMg&s" 
            },
            { 
                title: "Marketing Materials", 
                description: "Design posters, flyers, and banners to promote your brand.", 
                imageUrl: "https://images.pexels.com/photos/3184299/pexels-photo-3184299.jpeg?auto=compress&cs=tinysrgb&w=600" 
            },
        ],
        approach: [
            { 
                title: "Custom Graphic Design Services", 
                description: "We offer custom graphic design services to create visually compelling designs that elevate your brand identity. From logos to marketing materials, we ensure your designs resonate with your audience and make a lasting impression." 
            },
            { 
                title: "Digital and Print Design Expertise", 
                description: "Our graphic design solutions span both digital and print mediums, including social media graphics, brochures, banners, and more. We ensure high-quality, visually consistent designs optimized for every platform." 
            },
            { 
                title: "Creative and Brand-Focused Designs", 
                description: "We craft creative designs tailored to your brand’s personality and goals. By combining innovative visuals with strategic messaging, we help your brand stand out and engage your target audience effectively." 
            },
        ],
        seo: {
            title: "Creative Graphic Design Services | Arc Codes",
            description: "Bring your ideas to life with Arc Codes' unique graphic designs, tailored to captivate your audience and elevate your brand's identity.",
            keywords: "custom graphic design services, creative visuals for brands, professional graphic designers, unique brand identity design, marketing material design, arc codes, arc codes graphic design, branding design by arc codes",
            ogTitle: "Professional Graphic Design Services | Arc Codes",
            ogDescription: "Elevate your brand with Arc Codes' creative and compelling graphic designs that make a lasting impact on your audience.",
            ogImage: "https://example.com/graphic-design-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@graphicdesign_team"
        },
                 
    },

    elearnings: {
        title: "E-Learnings",
        description: "Develop engaging, interactive, and effective e-learning solutions tailored to your needs, designed to enhance learning experiences and drive better outcomes.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735299402/1859012c-fc43-49cf-b2fd-8dddd7de0cda.png",
        benefits: [
            { 
                title: "Interactive Modules", 
                description: "Engage learners with interactive course content.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180153/23730f49-0cbd-4f8a-a590-dd0ed517d38b.png" 
            },
            { 
                title: "SCORM Compatibility", 
                description: "Integrate courses with Learning Management Systems.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180088/1fc6f553-35ae-4d6c-a7e6-e8a2f93663ce.png" 
            },
            { 
                title: "Custom Animations", 
                description: "Bring concepts to life with tailored animations.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180194/8f3ea873-9ecb-4276-b27b-8d1ca2844e60.png" 
            },
        ],
        approach: [
            { 
                title: "Custom E-Learning Development", 
                description: "We create custom e-learning solutions designed to meet your unique training and educational goals. Our interactive courses combine engaging content, cutting-edge visuals, and user-friendly interfaces to deliver effective learning experiences." 
            },
            { 
                title: "Mobile and Web E-Learning Solutions", 
                description: "Our e-learning platforms are optimized for both mobile and web, ensuring seamless access across all devices. With responsive designs and adaptive learning features, we enhance learner engagement and retention." 
            },
            { 
                title: "Data-Driven and Scalable E-Learning Platforms", 
                description: "We build data-driven e-learning platforms with advanced analytics to track progress and outcomes. Designed for scalability, our solutions adapt to your growing needs while delivering measurable results for learners and organizations alike." 
            },
        ],
        seo: {
            title: "Interactive E-Learning Development Services | Arc Codes",
            description: "Enhance learning experiences with Arc Codes' custom e-learning solutions, featuring interactive modules, animations, and SCORM compatibility.",
            keywords: "custom e-learning development, interactive training solutions, SCORM compliant courses, corporate e-learning modules, mobile-friendly learning platforms, arc codes, arc codes e-learning development, e-learning solutions by arc codes",
            ogTitle: "Custom E-Learning Solutions | Arc Codes",
            ogDescription: "Boost learner engagement with Arc Codes' tailored e-learning solutions designed to deliver effective and interactive training experiences.",
            ogImage: "https://example.com/elearnings-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@elearnings_team"
        },
                 
    },

    dataAnalysis: {
        title: "Data Analysis",
        description: "Unlock actionable insights and drive informed decisions with advanced data analysis techniques tailored to your business goals.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180285/add6ed6b-1dc5-4180-8029-b48a4780a82e.png",
        benefits: [
            { 
                title: "Data Cleaning", 
                description: "Ensure your data is accurate and ready for analysis.", 
                imageUrl: "https://images.pexels.com/photos/3184303/pexels-photo-3184303.jpeg?auto=compress&cs=tinysrgb&w=600" 
            },
            { 
                title: "Predictive Modeling", 
                description: "Forecast trends and outcomes with confidence.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180997/1bb546b4-dfcb-49ef-adfc-d4e6f36fc276.png" 
            },
            { 
                title: "Custom Dashboards", 
                description: "Visualize key metrics to drive decisions.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180285/add6ed6b-1dc5-4180-8029-b48a4780a82e.png" 
            },
        ],
        approach: [
            { 
                title: "Custom Data Analysis Solutions", 
                description: "We provide custom data analysis services to transform raw data into actionable insights. Our expertise in advanced analytics helps businesses make data-driven decisions to optimize performance and achieve their goals." 
            },
            { 
                title: "Big Data and Predictive Analytics", 
                description: "Our data analysis solutions leverage big data technologies and predictive analytics to uncover trends, forecast outcomes, and identify opportunities. We deliver insights that drive innovation and competitive advantage." 
            },
            { 
                title: "Scalable and Secure Data Analysis Platforms", 
                description: "We develop scalable and secure data analysis platforms tailored to your business needs. With powerful tools and visualizations, we enable seamless integration, robust reporting, and effective decision-making." 
            },
        ],
        seo: {
            title: "Advanced Data Analysis Services | Arc Codes",
            description: "Leverage your data for actionable insights with Arc Codes' custom analysis solutions, designed to drive business growth through data-driven decision making.",
            keywords: "data insights for business, custom data analysis services, predictive data analytics, secure data platforms, business intelligence solutions, arc codes, arc codes data analysis, data analysis solutions by arc codes",
            ogTitle: "Data Analysis for Smarter Decisions | Arc Codes",
            ogDescription: "Unlock actionable insights and make informed decisions with Arc Codes' tailored data analysis solutions, driving smarter business strategies.",
            ogImage: "https://example.com/data-analysis-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@data_insights_team"
        },
                 
    },
    
    dataVisualization: {
        title: "Data Visualization",
        description: "Transform complex data into clear, compelling visuals that empower decision-making and provide valuable insights at a glance.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735299679/1b097c6f-b788-42fd-a68b-430ee7ad71fe.png",
        benefits: [
            { 
                title: "Interactive Charts", 
                description: "Drill down into data with dynamic visualizations.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180997/1bb546b4-dfcb-49ef-adfc-d4e6f36fc276.png" 
            },
            { 
                title: "Custom Reports", 
                description: "Deliver insights with visually striking reports.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180905/c97a03b6-4fef-458e-b2bd-a49017d980c2.png" 
            },
            { 
                title: "Geospatial Mapping", 
                description: "Map your data for location-based insights.", 
                imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdfpvkGvfzWyPj0t31jWiK8C5SsnxrbKsYZCl2lc1pfCZ4TC6JOTYWynoN74DROR4CJ9w&usqp=CAU" 
            },
        ],
        approach: [
            { 
                title: "Custom Data Visualization Services", 
                description: "We offer custom data visualization services to transform complex data into clear, compelling visuals. Our tailored dashboards and interactive charts help businesses make informed, data-driven decisions quickly and efficiently." 
            },
            { 
                title: "Interactive Dashboards and Reports", 
                description: "Our interactive dashboards and reports are designed to provide real-time insights. By integrating advanced tools like Power BI, Tableau, and custom solutions, we deliver dynamic, user-friendly visualizations that enhance understanding." 
            },
            { 
                title: "Scalable and Actionable Data Visualizations", 
                description: "We create scalable data visualization solutions that grow with your business. By turning raw data into actionable insights, we help organizations uncover trends, track KPIs, and present data in ways that drive impact." 
            },
        ],
        seo: {
            title: "Custom Data Visualization Services | Arc Codes",
            description: "Transform raw data into actionable insights with Arc Codes' interactive, scalable visualizations tailored to your business needs.",
            keywords: "interactive data dashboards, business visualization tools, custom data insights, scalable visualization solutions, real-time data charts, arc codes, arc codes data visualization, data visualization solutions by arc codes",
            ogTitle: "Advanced Data Visualization Solutions | Arc Codes",
            ogDescription: "Make informed decisions with visually compelling data insights, tailored for your business by Arc Codes.",
            ogImage: "https://example.com/data-visualization-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@dataviz_team"
        },
                 
    },

    machineLearning: {
        title: "Machine Learning",
        description: "Leverage cutting-edge machine learning techniques to drive innovation, optimize processes, and unlock new opportunities for growth.",
        imageUrl: "https://images.pexels.com/photos/8386440/pexels-photo-8386440.jpeg?auto=compress&cs=tinysrgb&w=600",
        benefits: [
            { 
                title: "Custom Algorithms", 
                description: "Solve complex challenges with tailored models.", 
                imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9DpagL62AiVozsNM_SEvFtfKN5evyW8zwZF6kLXVg3JWh3ICYP0ATqRVmUMbJXuBPEuA&usqp=CAU" 
            },
            { 
                title: "Natural Language Processing", 
                description: "Unlock insights from unstructured text data.", 
                imageUrl: "https://images.pexels.com/photos/3184634/pexels-photo-3184634.jpeg?auto=compress&cs=tinysrgb&w=600" 
            },
            { 
                title: "Image Recognition", 
                description: "Automate tasks with advanced image analysis.", 
                imageUrl: "https://images.pexels.com/photos/3183174/pexels-photo-3183174.jpeg?auto=compress&cs=tinysrgb&w=600" 
            },
        ],
        approach: [
            { 
                title: "Custom Machine Learning Solutions", 
                description: "We develop custom machine learning solutions to help businesses automate processes, uncover patterns, and make data-driven decisions. Our tailored models are designed to solve unique challenges across industries." 
            },
            { 
                title: "AI-Powered Predictive Analytics", 
                description: "Our machine learning services include AI-powered predictive analytics to forecast trends, optimize operations, and identify opportunities. By leveraging advanced algorithms, we deliver actionable insights that drive growth." 
            },
            { 
                title: "Scalable and Secure Machine Learning Models", 
                description: "We build scalable and secure machine learning models that integrate seamlessly with your systems. Our solutions are designed to evolve with your business, ensuring consistent performance and reliable results." 
            },
        ],
        seo: {
            title: "Custom Machine Learning Solutions | Arc Codes",
            description: "Revolutionize your business with Arc Codes' tailored machine learning models, predictive analytics, and advanced AI solutions designed to optimize processes and uncover opportunities.",
            keywords: "custom AI models, predictive analytics for business, tailored machine learning solutions, AI-powered optimization, scalable machine learning systems, arc codes, arc codes machine learning, AI solutions by arc codes",
            ogTitle: "Tailored Machine Learning and AI Solutions | Arc Codes",
            ogDescription: "Discover the power of custom AI and machine learning models by Arc Codes to streamline operations and drive business innovation.",
            ogImage: "https://example.com/machine-learning-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@ai_ml_experts"
        },
                  
    },
    
    businessIntelligence: {
        title: "Business Intelligence",
        description: "Make data-driven decisions with tailored business intelligence solutions that provide real-time insights, enhance efficiency, and drive strategic growth.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735299847/583c6c27-6c2b-4023-97e5-7dd2a865142d.png",
        benefits: [
            { title: "KPI Tracking", description: "Monitor performance with real-time dashboards.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736181134/74253cce-2116-4f66-a451-5f5a96c634f5.png" },
            { title: "Trend Analysis", description: "Identify market trends to stay ahead.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180905/c97a03b6-4fef-458e-b2bd-a49017d980c2.png" },
            { title: "Scalable Solutions", description: "Adapt your BI tools as your business grows.", imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736180534/0e591786-0165-4ae4-8350-f8c6a2c55909.png" },
        ],
        approach: [
            { title: "Custom Business Intelligence Solutions", description: "We provide custom business intelligence solutions to help organizations transform data into actionable insights. Our tailored dashboards and analytics empower businesses to make informed, data-driven decisions with confidence." },
            { title: "Advanced BI Tools and Integration", description: "Our expertise in advanced BI tools like Power BI, Tableau, and Looker enables seamless integration with your existing systems. We deliver real-time analytics and interactive reports to track KPIs and performance effectively." },
            { title: "Scalable and Secure BI Platforms", description: "We develop scalable and secure business intelligence platforms designed to grow with your organization. Our solutions ensure data accuracy, consistency, and accessibility for enhanced operational efficiency and strategic planning." }
        ],
        seo: {
            title: "Custom Business Intelligence Solutions | Arc Codes",
            description: "Empower your business with Arc Codes' tailored BI tools, real-time insights, and advanced analytics to drive strategic decisions and operational efficiency.",
            keywords: "tailored business intelligence tools, real-time analytics solutions, KPI tracking dashboards, advanced BI insights, scalable BI platforms, arc codes, arc codes business intelligence, BI solutions by arc codes",
            ogTitle: "Data-Driven Business Intelligence Services | Arc Codes",
            ogDescription: "Unlock actionable insights with advanced business intelligence tools and analytics by Arc Codes, tailored to your organization’s needs.",
            ogImage: "https://example.com/business-intelligence-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@business_intel_team"
        },
                 
    },    

    digitalMarketing: {
        title: "Digital Marketing",
        description: "Boost your brand presence and build meaningful connections with your audience through innovative and data-driven digital marketing strategies tailored to your goals.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736182085/61dd5db5-9ae4-4f42-b1b3-6999def11bc2.png",
        benefits: [
            { 
                title: "Social Media Strategy", 
                description: "Engage and grow your audience on platforms like Facebook, Instagram, and LinkedIn.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736181807/02be6efc-846a-4d7d-94b3-c8431e961549.png" 
            },
            { 
                title: "Ad Campaigns", 
                description: "Create targeted ad campaigns to maximize your reach and ROI.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736181992/ba23419b-09a3-4d51-90e4-a57223ba21f4.png" 
            },
            { 
                title: "Content Creation", 
                description: "Craft compelling content that resonates with your audience.", 
                imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1736178019/37b5621d-4148-458c-b50a-915c4c5f94cd.png" 
            },
        ],
        approach: [
            { 
                title: "Custom Social Media Marketing Services", 
                description: "We offer custom social media marketing services to help businesses build their online presence, engage with their audience, and drive conversions. Our strategies include tailored content creation, targeted campaigns, and analytics to maximize ROI." 
            },
            { 
                title: "Comprehensive Digital Marketing Solutions", 
                description: "Our digital marketing solutions cover SEO, PPC, email marketing, and more. By leveraging advanced tools and data-driven strategies, we enhance your brand visibility and drive measurable growth across digital platforms." 
            },
            { 
                title: "Scalable and Results-Driven Campaigns", 
                description: "We develop scalable, results-driven social media and digital marketing campaigns tailored to your business goals. With a focus on audience targeting, performance optimization, and actionable insights, we deliver impactful outcomes." 
            },
        ],
        seo: {
            title: "Data-Driven Digital Marketing Services | Arc Codes",
            description: "Amplify your brand's online presence with Arc Codes' targeted campaigns, content creation, and advanced analytics to maximize ROI and audience engagement.",
            keywords: "custom digital marketing strategy, targeted ad campaigns, social media growth services, data-driven marketing solutions, SEO-friendly content creation, arc codes, arc codes digital marketing, digital marketing solutions by arc codes",
            ogTitle: "Expert Digital Marketing Services | Arc Codes",
            ogDescription: "Grow your business online with personalized digital marketing solutions by Arc Codes, designed to engage audiences and drive measurable results.",
            ogImage: "https://example.com/digital-marketing-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@digital_marketing_team"
        },
        
    },

    seo: {
        title: "SEO Services",
        description: "Improve your search engine rankings and drive more organic traffic to your website with proven SEO strategies designed to increase visibility and attract your target audience.",
        imageUrl: "https://res.cloudinary.com/dt98ksead/image/upload/v1735300484/7aad8935-830c-431a-bc05-e3b2290578a0.png",
        benefits: [
            { 
                title: "Keyword Optimization", 
                description: "Identify and target the right keywords to boost your visibility.", 
                imageUrl: "https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=600" 
            },
            { 
                title: "On-Page SEO", 
                description: "Optimize your website's content and structure for search engines.", 
                imageUrl: "https://images.pexels.com/photos/3184642/pexels-photo-3184642.jpeg?auto=compress&cs=tinysrgb&w=600" 
            },
            { 
                title: "Backlink Building", 
                description: "Increase your site's authority with quality backlinks.", 
                imageUrl: "https://images.pexels.com/photos/768125/pexels-photo-768125.jpeg?auto=compress&cs=tinysrgb&w=600" 
            },
        ],
        approach: [
            { 
                title: "Custom SEO Services", 
                description: "We offer custom SEO services designed to improve your website’s search engine rankings and drive organic traffic. From keyword research to on-page and off-page optimization, we craft tailored strategies for measurable results." 
            },
            { 
                title: "Technical SEO and Website Optimization", 
                description: "Our technical SEO services ensure your website is optimized for speed, mobile-friendliness, and crawlability. By addressing backend issues, we enhance your site’s performance and search engine visibility." 
            },
            { 
                title: "Content-Driven and Scalable SEO Strategies", 
                description: "We develop content-driven and scalable SEO strategies that align with your business goals. With a focus on high-quality content, link building, and analytics, we help you stay ahead of the competition and attract more leads." 
            },
        ],
        seo: {
            title: "Affordable SEO Services | Arc Codes",
            description: "Improve your website rankings with Arc Codes' tailored SEO strategies focused on low-competition, high-impact keywords.",
            keywords: "affordable SEO solutions, custom SEO plans, organic traffic growth, business SEO services, local SEO optimization, arc codes, arc codes SEO services, SEO strategies by arc codes",
            ogTitle: "Tailored SEO Services for Business Growth | Arc Codes",
            ogDescription: "Boost your search engine rankings with cost-effective, targeted SEO strategies by Arc Codes.",
            ogImage: "https://example.com/seo-services-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@seo_experts_team"
        },
                 
    },

    home: {
        seo: {
            title: "Arc Codes | Innovative Software Development Solutions",
            description: "Arc Codes offers cutting-edge software development services, including web development, mobile app creation, and custom software solutions tailored to your business goals.",
            keywords: "Arc Codes, custom software development, web development company, mobile app solutions, scalable software services, innovative software development, tailored business software",
            ogTitle: "Arc Codes | Transforming Ideas Into Digital Solutions",
            ogDescription: "Discover innovative software development services at Arc Codes. From websites to mobile apps, we deliver solutions designed to grow your business.",
            ogImage: "https://example.com/arc-codes-homepage-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@ArcCodes_Official"
          }          
    },

    blog: {
        seo: {
            title: "Arc Codes Blog | Insights on Software Development and Technology Trends",
            description: "Explore the latest insights, tips, and trends in software development, web design, mobile apps, and emerging technologies on the Arc Codes Blog.",
            keywords: "Arc Codes blog, software development tips, technology insights, web design trends, mobile app development tips, software engineering articles, tech innovations",
            ogTitle: "Arc Codes Blog | Expert Tips and Trends in Software Development",
            ogDescription: "Stay updated with the latest software development trends and expert tips. Discover articles on web design, mobile apps, and innovative technology solutions.",
            ogImage: "https://example.com/arc-codes-blog-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@ArcCodes_Official"
          }          
    },

    team:{
        seo: {
            title: "Meet the Arc Codes Team | Expert Software Developers and Innovators",
            description: "Discover the passionate team behind Arc Codes. Meet our expert software developers, designers, and technology innovators who bring your ideas to life.",
            keywords: "Arc Codes team, expert software developers, software development team, innovative technology team, software engineers, creative designers, tech experts",
            ogTitle: "Meet the Arc Codes Team | Experts in Software Development",
            ogDescription: "Get to know the talented team at Arc Codes. Our experts in software development, design, and innovation are here to bring your vision to reality.",
            ogImage: "https://example.com/arc-codes-team-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@ArcCodes_Official"
          }
          
    },

    about:{
        seo: {
            title: "About Arc Codes | Innovators in Software Development",
            description: "Learn about Arc Codes, a leading software development company committed to delivering innovative, user-centric solutions that drive business growth.",
            keywords: "About Arc Codes, software development company, innovative software solutions, custom software developers, technology-driven solutions, software innovation",
            ogTitle: "About Arc Codes | Innovators in Software Development",
            ogDescription: "Discover Arc Codes, a software development company dedicated to creating innovative, scalable, and user-friendly solutions for businesses worldwide.",
            ogImage: "https://example.com/arc-codes-about-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@ArcCodes_Official"
          }          
    },

    contact:{
        seo: {
            title: "Contact Arc Codes | Get in Touch with Our Experts",
            description: "Reach out to Arc Codes for innovative software solutions. Contact our team to discuss your project, get support, or learn more about our services.",
            keywords: "Contact Arc Codes, software development inquiries, get in touch Arc Codes, software support, software consultation, software solutions contact",
            ogTitle: "Contact Arc Codes | Get in Touch with Our Experts",
            ogDescription: "Contact Arc Codes today for top-notch software solutions tailored to your business needs. Connect with our team of experts.",
            ogImage: "https://example.com/arc-codes-contact-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@ArcCodes_Official"
          }          
    },

    careers:{
        seo: {
            title: "Careers at Arc Codes | Join Our Innovative Team",
            description: "Explore exciting career opportunities at Arc Codes. Be a part of a dynamic team shaping the future of software development. Apply today!",
            keywords: "Careers at Arc Codes, software development jobs, work at Arc Codes, software careers, developer openings, tech company hiring, Arc Codes team opportunities",
            ogTitle: "Careers at Arc Codes | Join Our Innovative Team",
            ogDescription: "Looking for a rewarding career in software development? Join Arc Codes and work with a talented team on cutting-edge projects. Apply now!",
            ogImage: "https://example.com/arc-codes-careers-og-image.png",
            twitterCard: "summary_large_image",
            twitterCreator: "@ArcCodes_Official"
          },          
    }
    
};


export default services;