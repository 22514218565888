import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({
  title = 'Default Title',
  description = 'Default description',
  keywords = '',
  ogTitle,
  ogDescription,
  ogImage,
  twitterCard = 'summary',
  twitterCreator = '@default_creator',
}) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph metadata */}
      {ogTitle && <meta property="og:title" content={ogTitle || title} />}
      {ogDescription && <meta property="og:description" content={ogDescription || description} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      <meta property="og:type" content="website" />

      {/* Twitter metadata */}
      {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
}
