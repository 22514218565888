import React from 'react';
import './Footer.css';
import { Container, Grid, Typography, Box, IconButton } from '@mui/material';
import Logo from '../images/Log.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <Container maxWidth="lg">
        <Grid container spacing={5} alignItems="center">
          {/* Logo and Address Section */}
          <Grid item xs={12} md={4} className="footer-left">
            <img
              src={Logo}
              alt="Arc Codes Logo"
              className="footer-logo"
            />
            {/* <Typography variant="body2" className="footer-text">
              111, Venture Hub, Johar Town, Lahore, Pakistan
            </Typography> */}
            <Typography variant="body2" className="footer-text">
              Phone: +92 300 8104702
            </Typography>
            <Typography variant="body2" className="footer-text">
              Email: info@arccodes.com
            </Typography>
          </Grid>

          {/* Social Media Section */}
          <Grid item xs={12} md={4} className="footer-center">
                <Typography variant="h6" className="footer-heading">
                    Follow Us on Our Social Handles
                </Typography>
            <Box className="footer-icons">
                
              <IconButton
                aria-label="Facebook"
                href="https://facebook.com"
                target="_blank"
                className="footer-icon"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                aria-label="LinkedIn"
                href="https://linkedin.com"
                target="_blank"
                className="footer-icon"
              >
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={12} md={4} className="footer-right" sx={{marginTop:'7rem'}}>
            <Typography variant="h5" className="footer-heading" sx={{marginBottom: '1rem'}}>
              Quick Links
            </Typography>
            <Box className="footer-links">
              <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="body2" className="footer-link">About Us</Typography>
              </Link>
              <Link to="/blogs" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="body2" className="footer-link">Blog</Typography>
              </Link>
              <Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="body2" className="footer-link">Careers</Typography>
                </Link>
                <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="body2" className="footer-link">Contact Us</Typography>
                </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Copyright Section */}
      <Box className="footer-copyright">
        <Typography variant="body2" align="center">
          © {new Date().getFullYear()} Arc Codes (Pvt) Ltd. All rights reserved.
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
